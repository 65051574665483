import React from "react";

import "../../styles/globalStyles.css";

import Landing from "../../Pages/Landing";
import Links from "../Links";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const Home = () => {
  const location = useLocation();
  const pathParts = location.pathname.split("/"); // Split the path into parts
  const pubId = pathParts[2]; // Extract pubId from the path

  useEffect(() => {
    if (pubId) {
      redirectToApp(pubId);
    }
  }, [pubId]);

  const redirectToApp = (pubId: string) => {
    const appLink = `pintly://pub/${pubId}`;
    window.location.href = appLink;
  };

  return (
    <div className="mainContainer">
      <div style={{ height: "100vh", width: "100%" }}>
        <NavBar />
        <Landing />
      </div>
    </div>
  );
};

export default Home;
