import React from "react";
import "../../styles/globalStyles.css";
import "../../styles/globalStyles.css";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Footer from "../../Components/Footer";
const Landing = () => {
  const theme = useTheme();
  const words = ["Find", "Perfectly", "Priced", "Pints!"];
  const wordVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.08,
        type: "spring",
        stiffness: 100,
        damping: 10,
      },
    }),
  };
  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
        height: "85vh",
        alignItems: "center",
        flexDirection: "column",
        padding: "30px",
        paddingTop: "5px",
        [theme.breakpoints.down(700)]: {
          height: "90vh",
        },
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 900,
            fontSize: 60,
            textAlign: "center",
            [theme.breakpoints.down(700)]: {
              fontSize: 50,
            },
            [theme.breakpoints.down(600)]: {
              fontSize: 35,
            },
          }}
        >
          {words.map((word, i) => (
            <motion.span
              key={word}
              custom={i}
              initial="hidden"
              animate="visible"
              variants={wordVariants}
              style={{ display: "inline-block", marginRight: "8px" }}
            >
              {word}
            </motion.span>
          ))}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            textAlign: "center",
            fontWeight: 500,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: 23,
            paddingTop: 1,
            [theme.breakpoints.down(700)]: {
              fontSize: 20,
              paddingTop: 3,
            },
            [theme.breakpoints.down(600)]: {
              fontSize: 18,
            },
          }}
        >
          Pintly is your guide to every pint and pub in town!
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            height: "100px",
            paddingX: 10,
            [theme.breakpoints.down(750)]: {
              gap: 1,
              paddingTop: 3,
            },
          }}
        >
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://apps.apple.com/us/app/pintly/id6460936453"
          >
            <img
              src={require("../../assets/app-store-badge.png")}
              style={{ height: "150px", width: "150px" }}
            />
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://play.google.com/store/apps/details?id=com.pintly.pintlyclient"
          >
            <img
              src={require("../../assets/google_play.png")}
              style={{ height: "190px", width: "180px" }}
            />
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <img
          src={require("../../assets/landingImg1.png")}
          style={{
            maxHeight: "250px",
            height: "250px",
            maxWidth: "300px",
            width: "300px",
          }}
        />
      </Box>
      <Footer />
    </Box>
  );
};

export default Landing;
