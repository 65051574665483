import React, { useState } from "react";
import { motion } from "framer-motion";
import { screens } from "./data";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function NavItems() {
  const [selected, setSelected] = useState(0);
  const [hovered, setHovered] = useState<number | null>(null);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "40px",
        justifyContent: "center",
        position: "relative",
        zIndex: 10,
      }}
    >
      {screens.map(({ title, isLink, link }, i) => (
        <>
          {isLink ? (
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
              }}
              to={link ? link : ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                key={i}
                component={motion.li}
                onHoverStart={() => setHovered(i)}
                onHoverEnd={() => setHovered(null)}
                // onClick={() => setSelected(i)}
                sx={{
                  cursor: "pointer",
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  position: "relative",
                  zIndex: 10,

                  "&:after": {
                    // CSS for the underline
                    content: '""',
                    position: "absolute",
                    bottom: "-8px",
                    left: 0,
                    right: 0,
                    zIndex: 10,

                    height: "2px",
                    backgroundColor: screens[i].color,
                    width: selected === i || hovered === i ? "100%" : "0%", // Full width if selected or hovered, else no width
                    transition: "width 0.3s ease-in-out", // Smooth transition for the width
                  },
                }}
              >
                {title}
              </Box>
            </Link>
          ) : (
            <Box
              key={i}
              component={motion.li}
              onHoverStart={() => setHovered(i)}
              onHoverEnd={() => setHovered(null)}
              // onClick={() => setSelected(i)}
              sx={{
                cursor: "pointer",
                fontSize: "20px",
                fontFamily: "Poppins",
                fontWeight: 500,
                position: "relative",
                zIndex: 10,

                "&:after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-8px",
                  left: 0,
                  right: 0,
                  zIndex: 10,

                  height: "2px",
                  backgroundColor: screens[i].color,
                  width: selected === i || hovered === i ? "100%" : "0%", // Full width if selected or hovered, else no width
                  transition: "width 0.3s ease-in-out", // Smooth transition for the width
                },
              }}
            >
              {title}
            </Box>
          )}
        </>
      ))}
    </Box>
  );
}
