import { Box, Typography, useTheme } from "@mui/material";
import SidebarNav from "../SidebarNav";
import NavItems from "../NavItems/NavItems";

const NavBar = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          height: "15vh",
          zIndex: 10,
          [theme.breakpoints.down(750)]: {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginRight: 10,
            paddingLeft: 2,
            position: "absolute",
            top: "20px",
            left: "20px",
            alignItems: "center",
            [theme.breakpoints.down(750)]: {
              display: "none",
            },
          }}
        >
          <img
            style={{
              height: "60px",
              width: "60px",
              marginBottom: 20,
            }}
            src={require("../../assets/logoPintly.png")}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
            marginTop: "5px",
            gap: "20px",
            [theme.breakpoints.down(750)]: {
              display: "none",
            },
          }}
        >
          <NavItems />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          zIndex: 100,
          display: "none",
          [theme.breakpoints.down(750)]: {
            display: "block",
          },
        }}
      >
        <SidebarNav />
      </Box>
      <Box
        sx={{
          display: "none",
          [theme.breakpoints.down(750)]: {
            display: "flex",
            height: "10vh",
            justifyContent: "flex-end",
            width: "95%",
            alignItems: "center",
          },
        }}
      >
        <img
          style={{
            height: "50px",
            width: "50px",
          }}
          src={require("../../assets/logoPintly.png")}
        />
      </Box>
    </Box>
  );
};

export default NavBar;
