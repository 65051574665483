import "./styles/globalStyles.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Components/Home";
import Privacy from "./Components/Privacy";
import Terms from "./Components/Terms";

const ROUTER = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/pub/:id",
    element: <Home />,
  },
]);

const App = () => {
  return <RouterProvider router={ROUTER} />;
};

export default App;
