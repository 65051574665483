import { motion } from "framer-motion";
import { MenuItem } from "./Item";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Navigation = ({ isOpen }: { isOpen: boolean }) => (
  <motion.ul
    style={{
      display: isOpen ? "flex" : "none",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "35px",
    }}
  >
    <Link
      to="https://linktr.ee/pintly"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "30px",
        padding: "20px",
        zIndex: 3,
        textDecoration: "none",
        color: "#000",
      }}
    >
      <Typography
        sx={{
          zIndex: 100,
          fontFamily: "Poppins",
          fontSize: "26px",
          fontWeight: 600,
        }}
      >
        Contact
      </Typography>
    </Link>
    <Link
      to="https://portal.pintly.app/Login"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "30px",
        padding: "20px",
        zIndex: 3,
        textDecoration: "none",
        color: "#000",
      }}
    >
      <Typography
        sx={{
          zIndex: 100,
          fontFamily: "Poppins",
          fontSize: "26px",
          fontWeight: 600,
        }}
      >
        Pub Portal
      </Typography>
    </Link>
  </motion.ul>
);
