import { Box } from "@mui/material";
import React from "react";
import "../../styles/globalStyles.css";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        // position: "absolute",
        // bottom: 0,
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <a target="_blank" className="links" href="/terms">
          Terms and conditions
        </a>
      </Box>
      <Box>
        <a target="_blank" className="links" href="/privacy">
          Privacy Policy
        </a>
      </Box>
    </Box>
  );
};

export default Footer;
